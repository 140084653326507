<template>
	<div class="hidden"></div>
</template>

<script>
	import i18n from "@/i18n";

	export default {
		name: "InvitationDeleter",
		props: {
			selectedInvite: {
				type: Object,
				default: null
			},
			appId: {
				type: Number,
				required: true
			}
		},
		watch: {
			selectedInvite: function (newVal, oldVal) {
				if (newVal && oldVal !== newVal) {
					this.confirmDelInvite();
				}
			}
		},
		methods: {
			confirmDelInvite() {
				this.$vs.dialog({
					type: "confirm",
					color: "danger",
					title: i18n.t("Supprimer l'invitation"),
					text: i18n.t("Êtes-vous certain de vouloir supprimer l'invitation de ...", {
						firstname: this.selectedInvite.firstname,
						lastname: this.selectedInvite.lastname
					}),
					accept: this.delInvite,
					cancel: this.refuse,
					acceptText: i18n.t("Oui, supprimer l'invitation"),
					cancelText: i18n.t("Non")
				});
			},
			refuse() {
				this.$emit("done", false);
			},
			delInvite() {
				const { id, recipient_user } = this.selectedInvite;

				const payload = {
					appId: this.appId,
					id
				};

				this.$store
					.dispatch("deleteInviteById", payload)
					.then(async () => {
						//this.app = await this.$store.dispatch("getAppByAppId", this.appId);
						this.$vs.notify({
							title: i18n.t("Invitation supprimée !"),
							text: i18n.t("L'invitation a bien été supprimée."),
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
						this.$emit("done", true);
					})
					.catch(error => {
						console.error(error);

						this.$vs.notify({
							title: i18n.t("Impossible de supprimer l'invitation"),
							text: i18n.t("L'invitation de ... n'a pas pu être supprimée", {
								firstname: recipient_user.firstname,
								lastname: recipient_user.lastname
							}),
							color: "danger",
							iconPack: "feather",
							icon: "icon-alert-triangle",
							time: 15000
						});
						this.$emit("done", false);
					});
			}
		}
	};
</script>
