<template>
	<div id="accountants">
		<!-- en-tête nom et logo entreprise  -->
		<div class="vx-row mt-3 mb-6 flex justify-between">
			<div class="vx-col w-full sm:w-1/2 flex justify-center sm:justify-between">
				<h2 class="title-sinao self-center" style="line-height: 35px">{{ $t("Mes collaborateurs") }}</h2>
			</div>

			<div class="w-fit vx-col flex justify-center sm:justify-end">
				<s-button
					icon="icon-plus"
					type="border"
					class="sm:m-base float-none sm:float-right"
					:disabled="loading"
					@click="$router.push({ name: 'Invite-accountant' })"
				>
					{{ $t("Ajouter un collaborateur") }}
				</s-button>
			</div>
		</div>

		<!-- Team members -->
		<vx-card class="mt-4 text-center shadow-none border" v-if="user.accounting_firm">
			<h4 class="mb-3">{{ $t("Gestion des membres") }}</h4>

			<template v-for="accountant in user.accounting_firm?.users?.filter(u => u.id !== user.id) ?? []">
				<accountant-item
					:key="'accountant_' + accountant.id"
					:accountant="accountant"
					:apps="accounting_firm_apps"
				/>
			</template>
		</vx-card>

		<!-- Invitations -->
		<template v-if="user.accounting_firm?.invitations && user.accounting_firm?.invitations.length > 0">
			<h4 class="mb-5 mt-10">{{ $t("Invitations en attente") }}</h4>

			<!-- On peut rajouter les attributs pagination & search si trop d'utilisateurs max-items="x" -->
			<vs-table
				ref="tableInviteAccountants"
				class="text-left relative"
				stripe
				:data="filtered_invitations"
				hover-flat
			>
				<template slot="thead">
					<vs-th />
					<vs-th>{{ $t("Nom") }}</vs-th>
					<vs-th>{{ $t("Email") }}</vs-th>
					<vs-th>{{ $t("Action") }}</vs-th>
				</template>

				<template slot-scope="{ data }">
					<vs-tr v-for="invite in data" :key="invite.id">
						<!-- Colonne 1 : photo avec gestion si admin -->
						<vs-td class="photoCol">
							<img
								:src="`https://sinao.app/services/avatar/${invite.firstname}${invite.lastname}/${invite.firstname[0]}${invite.lastname[0]}`"
								class="rounded-full"
								height="50px"
								width="50px"
								alt
							/>
						</vs-td>

						<!-- Colonne 2 : prénom + nom -->
						<vs-td>{{ invite.firstname }} {{ invite.lastname }}</vs-td>

						<!-- Colonne 3 : email -->
						<vs-td>{{ invite.email }}</vs-td>

						<!-- Colonne 4 : action -->
						<vs-td>
							<div class="flex">
								<vs-button
									class="ml-3"
									radius
									size="large"
									color="danger"
									type="border"
									icon-pack="feather"
									icon="icon-delete"
									@click="confirmDelInvite(invite)"
								/>
							</div>
						</vs-td>
					</vs-tr>
				</template>
			</vs-table>
		</template>
		<!-- invitation deleter component -->
		<invitation-deleter
			:selected-invite="selectedInvite"
			:app-id="selectedInvite ? parseInt(selectedInvite.appId) : 0"
			@done="confirmDelDone"
		/>
	</div>
</template>

<script>
	//import { Fragment } from "vue-fragment";
	import { mapGetters, mapState } from "vuex";
	import AccountantItem from "@/components/AccountantItem.vue";
	import InvitationDeleter from "@/components/InvitationDeleter.vue";
	export default {
		name: "Accountants",
		components: { AccountantItem, InvitationDeleter },
		data() {
			return {
				types: ["default", "point", "radius", "corners", "border", "sound", "material"],
				loading: false,
				selectedInvite: null
			};
		},
		computed: {
			...mapGetters(["getImageUrl", "accounting_firm_apps"]),
			...mapState(["user"]),
			filtered_invitations() {
				if (!this.user.accounting_firm.invitations) return [];

				return this.user.accounting_firm.invitations.reduce((acc, invite) => {
					if (acc.find(item => item.email === invite.email) === undefined) acc.push(invite);
					return acc;
				}, []);
			}
		},
		methods: {
			confirmDelInvite(invite) {
				this.selectedInvite = invite; // component InvitationDeleter handle
			},
			async confirmDelDone(confirm_value) {
				if (!confirm_value) {
					this.selectedInvite = null;
				} else {
					// reload me
					await this.$store.dispatch("getUser");
				}
			}
		}
	};
</script>

<style scoped>
	.selector_button {
		padding: 0.4rem 0.6rem !important;
	}
</style>
