<template>
	<vs-card class="shadow-none my-3">
		<!-- div contenant la totalité de la card -->
		<div class="vx-row">
			<!-- div contenant logo + infos organization & users -->
			<div class="table">
				<!-- Div logo organization -->
				<div class="table-row">
					<div class="table-cell">
						<img
							:src="getImageUrl(accountant)"
							class="rounded-full shadow-md m-auto mr-2"
							height="35px"
							width="35px"
							style="object-fit: contain"
						/>
					</div>
					<!-- Nom du collaborateur -->
					<div class="table-cell md:text-left md:ml-3" style="vertical-align: middle">
						<h5 class="title-sinao font-bold pb-1">{{ accountant.name }}</h5>
					</div>
				</div>
			</div>

			<!-- Liste des apps de l'utilisateur -->
			<div class="w-full flex-1 flex justify-end">
				<div class="flex pl-4">
					<vs-tooltip
						v-for="app in getAccountantApps()"
						:key="'app_' + app.id"
						:color="app.bgcolor"
						:text="app.organization.name"
					>
						<div
							class="rounded-full shadow-md text-white uppercase text-lg font-medium"
							:style="{
								'background-color': app.bgcolor,
								width: '35px',
								height: '35px',
								'margin-left': '-8px',
								'line-height': '35px'
							}"
						>
							{{ app.initials }}
						</div>
					</vs-tooltip>
				</div>
			</div>
			<!-- bouton et dropdown d'attribution des apps -->
			<accountant-apps-selector :accountant="accountant" :apps="apps" />
		</div>
	</vs-card>
</template>

<script>
	import { mapGetters } from "vuex";
	import AccountantAppsSelector from "@/components/AccountantAppsSelector.vue";
	export default {
		name: "AccountantItem",
		components: { AccountantAppsSelector },
		props: {
			accountant: {
				type: Object,
				default: null
			},
			apps: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			...mapGetters(["getImageUrl"])
		},
		methods: {
			stringToHSLColor: function (inputString, s = 70, l = 60) {
				let hash = 0;
				for (let i = 0; i < inputString.length; i++) {
					hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
				}

				const hue = hash % 360;
				return `hsl(${hue}, ${s}%, ${l}%)`;
			},
			getAccountantApps: function () {
				const accountant_apps = [
					...new Set([...this.accountant.apps_by_policies, ...this.accountant.apps_by_admin])
				];
				return accountant_apps.map(app => {
					app["bgcolor"] = this.stringToHSLColor(app.organization.name);
					app["initials"] = app.organization.name
						.split(" ")
						.map(name_part => name_part.substring(0, 1))
						.join("");
					return app;
				});
			}
		}
	};
</script>
