var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-custom-content":"","vs-trigger-click":""}},[_c('a',{attrs:{"href.prevent":""}},[_c('div',{staticClass:"rounded-full shadow-md text-primary",style:({
					'background-color': 'white',
					width: '35px',
					height: '35px',
					'margin-left': '-5px',
					'line-height': '36px'
				})},[_c('vs-icon',{attrs:{"icon-pack":"feather","icon":"icon-settings","size":"18px"}})],1)]),_c('vs-dropdown-menu',[_c('div',{staticClass:"p-3 apps_container"},[_c('h4',{staticClass:"mt-2 my-4"},[_vm._v(_vm._s(_vm.$t("Gérer les acces de", { name: _vm.accountant.name })))]),_c('div',{staticClass:"flex py-1 mb-3 rounded-lg",staticStyle:{"background-color":"#eee"}},[_c('vs-checkbox',{on:{"change":_vm.toggleAllSelectedApps},model:{value:(_vm.select_all),callback:function ($$v) {_vm.select_all=$$v},expression:"select_all"}}),_vm._v(" "+_vm._s(_vm.$t("tout cocher decocher"))+" ")],1),_vm._l((_vm.apps),function(app,index){return _c('div',{key:_vm.accountant.id + '_app_' + app.id + '_' + index,staticClass:"flex my-2"},[_c('vs-checkbox',{model:{value:(_vm.accountant_selected_apps[app.id]),callback:function ($$v) {_vm.$set(_vm.accountant_selected_apps, app.id, $$v)},expression:"accountant_selected_apps[app.id]"}}),_vm._v(" "+_vm._s(_vm.formatOrganizationName(app.organization.name, 30))+" ")],1)}),_c('div',{staticClass:"flex my-2 pt-3"},[_c('s-button',{staticClass:"selector_button",attrs:{"type":"danger"},on:{"click":_vm.confirmDelAccountant}},[_c('span',{staticClass:"text-sm text-white font-semibold"},[_vm._v(_vm._s(_vm.$t("supprimer tous les acces", { name: _vm.accountant.civil_name })))])]),_c('vs-spacer'),_c('s-button',{staticClass:"selector_button",attrs:{"type":"success"},on:{"click":_vm.savePolicies}},[_c('span',{staticClass:"text-sm text-white font-semibold"},[_vm._v(_vm._s(_vm.$t("Enregistrer")))])])],1)],2)])],1),_c('policies-deleter',{attrs:{"selected-member":_vm.selectedMember,"app-id":parseInt(_vm.appId),"type":"acces"},on:{"done":_vm.confirmDelAccessDone}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }