<template>
	<div>
		<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
			<a href.prevent>
				<div
					class="rounded-full shadow-md text-primary"
					:style="{
						'background-color': 'white',
						width: '35px',
						height: '35px',
						'margin-left': '-5px',
						'line-height': '36px'
					}"
				>
					<vs-icon icon-pack="feather" icon="icon-settings" size="18px" />
				</div>
			</a>

			<vs-dropdown-menu>
				<div class="p-3 apps_container">
					<!-- selector title -->
					<h4 class="mt-2 my-4">{{ $t("Gérer les acces de", { name: accountant.name }) }}</h4>
					<!-- select all app -->
					<div class="flex py-1 mb-3 rounded-lg" style="background-color: #eee">
						<vs-checkbox v-model="select_all" @change="toggleAllSelectedApps"></vs-checkbox>
						{{ $t("tout cocher decocher") }}
					</div>
					<!-- app list -->
					<div
						v-for="(app, index) in apps"
						:key="accountant.id + '_app_' + app.id + '_' + index"
						class="flex my-2"
					>
						<vs-checkbox v-model="accountant_selected_apps[app.id]"></vs-checkbox>
						{{ formatOrganizationName(app.organization.name, 30) }}
					</div>
					<!-- buttons -->
					<div class="flex my-2 pt-3">
						<s-button type="danger" class="selector_button" @click="confirmDelAccountant">
							<span class="text-sm text-white font-semibold">{{
								$t("supprimer tous les acces", { name: accountant.civil_name })
							}}</span>
						</s-button>
						<vs-spacer></vs-spacer>
						<s-button type="success" class="selector_button" @click="savePolicies">
							<span class="text-sm text-white font-semibold">{{ $t("Enregistrer") }}</span>
						</s-button>
					</div>
				</div>
			</vs-dropdown-menu>
		</vs-dropdown>
		<!-- user deleter component -->
		<policies-deleter
			:selected-member="selectedMember"
			:app-id="parseInt(appId)"
			type="acces"
			@done="confirmDelAccessDone"
		/>
	</div>
</template>

<script>
	import i18n from "@/i18n";
	import PoliciesDeleter from "@/components/PoliciesDeleter.vue";
	import { mapState } from "vuex";

	export default {
		name: "AccountantAppsSelector",
		components: { PoliciesDeleter },
		props: {
			accountant: {
				type: Object,
				default: null
			},
			apps: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				select_all: false,
				accountant_selected_apps: {},
				selectedMember: null
			};
		},
		computed: {
			...mapState(["user"]),
			appId() {
				return this.user?.accounting_firm?.appId ?? 0;
			}
		},
		watch: {
			accountant: {
				handler(newVal) {
					this.accountant_selected_apps = Object.assign({});
					this.apps.forEach(app => {
						this.accountant_selected_apps[app.id] =
							newVal.apps_by_policies.find(p => p.id === app.id) !== undefined ||
							newVal.apps_by_admin.find(a => a.id === app.id) !== undefined;
					});
				},
				deep: true, // Surveille les changements imbriqués
				immediate: true // Déclenche le watcher immédiatement à l'initialisation
			}
		},
		methods: {
			formatOrganizationName: function (name, maxLength) {
				if (name.length > maxLength) {
					return name.substring(0, maxLength) + "...";
				}
				return name;
			},
			toggleAllSelectedApps: function () {
				Object.keys(this.accountant_selected_apps).forEach(
					key => (this.accountant_selected_apps[key] = this.select_all)
				);
			},
			savePolicies: function () {
				const payload = {
					userId: this.accountant.id,
					appId: Object.keys(this.accountant_selected_apps).filter(
						key => this.accountant_selected_apps[key] === true
					)
				};
				this.$store
					.dispatch("saveAccountingFirmUserPolicies", payload)
					.then(() => {
						this.$vs.notify({
							title: i18n.t("Accès mis à jour"),
							text: i18n.t("Les accès de mis à jour", { name: this.accountant.name }),
							color: "success",
							iconPack: "feather",
							icon: "icon-check",
							time: 5000
						});
						this.$store.dispatch("getUser");
					})
					.catch(error => {
						this.$vs.notify({
							title: i18n.t("Erreur mise à jour"),
							text: i18n.t("Les accès de pas mis à jour", {
								name: this.accountant.name,
								error: error.message ?? ""
							}),
							color: "danger",
							iconPack: "feather",
							icon: "icon-alert-triangle",
							time: 15000
						});
					});
			},
			confirmDelAccountant: function () {
				this.selectedMember = this.accountant;
			},
			async confirmDelAccessDone(confirm_value) {
				if (!confirm_value) {
					this.selectedMember = null;
				} else {
					await this.$store.dispatch("getUser");
				}
			}
		}
	};
</script>

<style scoped>
	.apps_container {
		max-height: 40vh;
		overflow: scroll;
		min-width: 280px;
	}

	.selector_button {
		padding: 0.4rem 0.6rem;
	}
</style>
